body{
    /*background: rgb(168,167,172);
    background: linear-gradient(90deg, rgba(168,167,172,1) 0%, rgba(178,178,192,0.841631635564382) 35%, rgba(166,207,208,1) 100%);
    */
    background-image: url("/images/vesta/Piso-Aquecido-assentamento-do-piso.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
}

.main-head{
    height: 150px;
    background: #FFF;
   
}

.sidenav {
    height: 100%;
    background-color: #FFF;
    overflow-x: hidden;
    padding-top: 20px;
}

.main {
    padding: 0px 10px;
}


@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
}

@media screen and (max-width: 450px) {
    .login-form{
        margin-top: 10%;
    }

    .register-form{
        margin-top: 10%;
    }
}

@media screen and (min-width: 768px){
    .main{
        margin-left: 30%; 
    }

    .sidenav{
        width: 30%;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
    }

    .login-form {
        margin-top: 40%;
        color: #000;
    }

    .register-form{
        margin-top: 20%;
    }
}


.login-main-text{
    padding: 60px;
    color: #f30404;
}

.login-main-text h2{
    font-weight: 300;
}